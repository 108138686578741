<template>
  <v-container>
    <v-overlay :value="loading" v-if="loading">
      <v-progress-circular color="primary" indeterminate size="164">
        <v-avatar size="70">
          <v-img src="@/assets/logo1.svg"> </v-img>
        </v-avatar>
      </v-progress-circular>
    </v-overlay>
    <div v-else-if="workSample.title != null">
      <v-card flat>
        <v-card-subtitle class="d-flex flex-wrap ma-1">
          <p class="ma-1">
            {{ workSample.createdDate | moment("YYYY/MM/DD - h:mm a ") }}
          </p>
          <!-- <v-spacer/> -->

          <i class="ma-1" v-if="isInRole(2)">-</i>
          <b class="ma-1" v-if="isInRole(2)">
            {{ workSample.student.studentFullName }}
          </b>
        </v-card-subtitle>
        <v-card-title>
          <h2>
            {{ workSample.title }}
          </h2>
          <v-spacer />
          <v-btn tile color="primary" @click="back()" class="rounded-xl ma-1">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-wrap">
          <v-btn
            small
            class="ma-1"
            v-if="isInRole(0)"
            :color="workSample.flag ? 'success white--text' : ''"
            @click="ToggleFlag(workSample.workSampleGuid)"
          >
            <v-icon>mdi-flag</v-icon>
          </v-btn>
          <v-btn v-else small class="ma-1" :color="workSample.flag ? 'success white--text' : ''">
            <v-icon>mdi-flag</v-icon>
          </v-btn>
          <v-btn
            small
            class="ma-1"
            v-if="isInRole(0)"
            :color="workSample.forAttention ? 'yellow accent-4 white--text' : ''"
            @click="ToggleForAttention(workSample.workSampleGuid)"
          >
            <v-icon>mdi-star</v-icon>
          </v-btn>
          <v-btn
            v-else
            small
            class="ma-1"
            :color="workSample.forAttention ? 'yellow accent-4 white--text' : ''"
          >
            <v-icon>mdi-star</v-icon>
          </v-btn>
          <v-btn color="primary" class="ma-1" small @click="showDialog = true">
            {{ $t("portfolio.WorkSampleTable.show") }}
          </v-btn>
          <edit-work-sample
            :absolute="true"
            v-if="isInRole(0)"
            @putMethod="getWorkSample(workSample.workSampleGuid)"
            :id="workSample.workSampleGuid"
          />
          <show-work-sample-content
            @close="showDialog = false"
            :showDialog="showDialog"
            :title="workSample.title"
            :content="workSample.description"
          ></show-work-sample-content>
        </v-card-text>
      </v-card>
      <template>
        <v-tabs v-model="tab">
          <v-tab @click="tab = 0" class="font-weight-bold">{{
            $t("portfolio.Reflections.title")
          }}</v-tab>
          <v-tab @click="tab = 1" link class="font-weight-bold">
            {{ $t("portfolio.TagsItem.title") }}
          </v-tab>
          <v-tab @click="tab = 2" class="font-weight-bold">
            {{ $t("portfolio.Feedback.title") }}
          </v-tab>
          <v-tab @click="tab = 3" class="font-weight-bold">
            {{ $t("portfolio.comment.title") }}
          </v-tab>
        </v-tabs>
      </template>
      <v-divider class="mb-1"></v-divider>
      <!-- <KeepAlive>
          <router-view></router-view>
        </KeepAlive> -->
      <div v-if="tab === 0">
        <reflections />
      </div>
      <div v-if="tab === 1">
        <tags />
      </div>
      <div v-if="tab === 2">
        <feedback />
      </div>
      <div v-if="tab === 3">
        <comments />
      </div>
    </div>
    <v-img v-else src="/src/assets/noData.svg"> </v-img>
  </v-container>
</template>

<script>
// import EditTagItem from "../../components/portfolio/TagsItem/editTagItem.vue";
// import AddTagItem from "../../components/portfolio/TagsItem/addTagItem.vue";
import EditWorkSample from "@/components/portfolio/workSample/editWorkSample.vue";
import ShowWorkSampleContent from "@/components/portfolio/workSample/showWorkSampleContent.vue";
import Reflections from "./workSampleInnerPages/reflections.vue";
import Comments from "./workSampleInnerPages/comments.vue";
import Tags from './workSampleInnerPages/tags.vue';
import Feedback from './workSampleInnerPages/feedback.vue';

export default {
  components: {
    EditWorkSample,
    ShowWorkSampleContent,
    Reflections,
    Comments,
    Tags,
    Feedback,
    // EditTagItem,
    // AddTagItem
  },
  data() {
    return {
      tab: 0,
      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000"
        }
      ],
      workSample: {
        workSampleGuid: null,
        title: null,
        tags: [],
        flag: false,
        forAttention: false,
        createdDate: null,
        description: null,
        student: {
          studentFullName: null,
          studentGuid: null
        }
      },
      loading: false,
      tagsLoading: false,
      showDialog: false,
      addDialog: false,
      tags: [],
      searchTag: null
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    openAddDialog() {
      this.addDialog = true;
    },
    async getWorkSample(id) {
      this.loading = true;
      this.workSample.workSampleGuid = null;
      this.workSample.title = null;
      this.workSample.flag = false;
      this.workSample.forAttention = false;
      this.workSample.description = null;
      this.workSample.createdDate = null;
      this.workSample.tags = [];
      await this.axios
        .get(`WorkSample/Get/${id}`)
        .then(response => {
          // console.log(response.data.data);
          // eslint-disable-next-line object-curly-newline
          const {
            workSampleGuid,
            title,
            tags,
            flag,
            forAttention,
            description,
            createdDate,
            student
          } = response.data.data;
          this.workSample.workSampleGuid = workSampleGuid;
          this.workSample.title = title;
          this.workSample.createdDate = createdDate;
          this.workSample.description = description === undefined ? null : description;
          this.workSample.flag = flag;
          this.workSample.forAttention = forAttention;
          this.workSample.student.studentFullName = student.studentFullName;
          this.workSample.student.studentGuid = student.studentGuid;
          // this.workSample.tags = Array.isArray(tags) ? tags : [];
          if (Array.isArray(tags)) {
            tags.forEach(obj => {
              const switchItem = this.swatches.find(sw => sw.hexa === obj.tagColor);
              const toAddTag = {
                hover: false,
                tagItemGuid: obj.tagItemGuid,
                tagGuid: obj.tagGuid,
                tagName: obj.tagName,
                tagColor: obj.color,
                tagTextColor: switchItem !== undefined ? switchItem.textColor : "white"
              };
              // console.log(toAddTag);
              this.workSample.tags.push(toAddTag);
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getTagsItem() {
      this.tagsLoading = true;
      this.searchTag = null;
      this.tags = [];
      this.axios
        .get("TagItem/Get")
        .then(response => {
          const { data } = response.data;
          // console.log(response.data);
          if (Array.isArray(data)) {
            data.forEach(sample => {
              // eslint-disable-next-line array-callback-return
              const color = this.swatches.find(x => {
                if (
                  sample.tagColor !== undefined &&
                  sample.tagColor !== "undefined" &&
                  x.hexa === sample.tagColor
                ) {
                  return x;
                }
              });
              // console.log(color);
              const tag = {
                tagColor: sample.tagColor,
                tagItemGuid: sample.tagItemGuid,
                tagName: sample.tagName,
                tagTextColor: color !== undefined ? color.textColor : "white",
                hover: false
              };
              this.tags.push(tag);
            });
            if (this.tags.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(err => {
          console.log("error:", err);
        })
        .finally(() => {
          this.tagsLoading = false;
        });
    },

    async setTagToWorkSample(sampleId, tag) {
      // console.log(sampleId, tag);
      if (Array.isArray(this.workSample.tags)) {
        const exists = this.workSample.tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
        // console.log(exists);
        if (!exists) {
          this.loading = true;
          await this.axios
            .post("Tag/Create", {
              tagItemGuid: tag.tagItemGuid,
              workSampleGuid: sampleId
            })
            .then(result => {
              // console.log(result);
              if (result.data.details === "Tag Already Exist.") {
                this.loading = false;
                this.$swal.fire({
                  title: this.$t("portfolio.TagsItems.AlreadyExist"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: toast => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  }
                });
                return;
              }
              // if (Array.isArray(tags) && tags.length <= 5) {
              // this.workSample.tags.push(toAddTag);
              // }
              this.loading = false;
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
              this.getWorkSample(this.$route.params.id);
            })
            .catch(() => {
              this.loading = false;
              this.$swal.fire({
                title: this.$t("AnErrorOccurredDuringTheProcess"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            });
          // setTimeout(() => {
          //   tags.push(toAddTag);
          //   this.loading = false;
          // }, 300);
        }
      }
    },
    editTagItem(tag) {
      // console.log("Edited");
      // eslint-disable-next-line prefer-const
      let object = this.tags.find(x => x.tagItemGuid === tag.tagItemGuid);
      if (object) {
        object.tagName = tag.tagName;
        object.tagColor = tag.tagColor;
        object.tagTextColor = tag.textColor;
      }
      // Iterate through the workSamples array
      this.workSamples.forEach(workSample => {
        // Check if the workSample has a tags array
        if (Array.isArray(workSample.tags)) {
          // Find the tag with the same tagItemGuid in the workSample's tags array
          const workSampleTag = workSample.tags.find(x => x.tagItemGuid === tag.tagItemGuid);

          // Update the tag in the workSample's tags array if found
          if (workSampleTag) {
            workSampleTag.tagName = tag.tagName;
            workSampleTag.tagColor = tag.tagColor;
            workSampleTag.tagTextColor = tag.textColor;
          }
        }
      });
    },
    openTagItemEdit(item) {
      this.tag.tagItemGuid = item.tagItemGuid;
      this.tag.tagColor = item.tagColor;
      this.tag.tagName = item.tagName;
      this.tag.tagTextColor = item.tagTextColor;
      // this.tag = item;
      this.dialog = true;
    },
    setTag(item) {
      this.tag.tagColor = item.tagColor;
      this.tag.tagItemGuid = item.tagItemGuid;
      this.tag.tagTextColor = item.tagTextColor;
      this.tag.tagName = item.tagName;
      this.dialog = true;
    },

    async removeTagToWorkSample(tag) {
      const exists = this.workSample.tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
      // console.log(exists);
      // console.log(exists, this.tags);
      if (exists) {
        // Add the tag to the array if it's not already present
        const tagItem = this.workSample.tags.findIndex(sw => sw.tagItemGuid === tag.tagItemGuid);
        const tagItemObj = this.workSample.tags.find(sw => sw.tagItemGuid === tag.tagItemGuid);
        console.log(tagItemObj);
        await this.axios
          .post("Tag/Delete", { tagGuid: tagItemObj.tagGuid })
          .then(() => {
            this.workSample.tags.splice(tagItem, 1);
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
            // this.tags = [];
            // this.getTags(this.id);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          });
      }
    },
    async ToggleForAttention(id) {
      this.loading = true;
      await this.axios
        .post(`WorkSample/ToggleForAttention?worksampleGuid=${id}`)
        .then(response => {
          this.workSample.forAttention = response.data;
          this.loading = false;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
        })
        .catch(() => {
          this.loading = false;
          this.$swal
            .fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
    async ToggleFlag(id) {
      this.loading = true;
      await this.axios
        .post(`WorkSample/ToggleFlag?worksampleGuid=${id}`)
        .then(response => {
          this.workSample.flag = response.data;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$swal
            .fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
    }
  },
  computed: {
    tagsFilter() {
      const data = this.tags;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.searchTag) {
        return data.filter(
          item =>
            // eslint-disable-next-line implicit-arrow-linebreak
            this.searchTag
              .toLowerCase()
              .split(" ")
              .every(v => item.tagName.toLowerCase().includes(v))
          // eslint-disable-next-line function-paren-newline
        );
      } else {
        return data;
      }
    }
  },
  created() {
    this.getWorkSample(this.$route.params.id);
    // console.log("Parent", this.$route);
  }
};
</script>

<style scoped>
#tagSearch {
  width: 150px !important;
  max-width: 100% !important;
}
#tagSearch >>> * {
  font-size: 14px !important;
}

.fixed-item {
  display: flex;
  height: 10px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30px;
  /* Optional, add background color as needed */
  z-index: 1; /* Optional, adjust z-index as needed */
  /* Add any other styles for your fixed item */
}

.scrollable-list {
  z-index: 2;
  margin-top: 70px;
  max-height: 250px; /* Set the maximum height for scrollable list */
  overflow-y: auto; /* Add a vertical scrollbar if necessary */
  /* Add any other styles for your scrollable list */
}
</style>
