<template>
  <v-container>
    <v-card flat>
      <v-toolbar color="primary white--text" flat>
        <v-card-title class="ma-1">
          {{ $t("portfolio.Feedback.title") }}
        </v-card-title>
        <v-spacer />
        <add-feedback
          v-if="isInRole(2)"
          @refreshData="get(sourceGuid, sourceType)"
          :sourceType="sourceType"
          :sourceGuid="sourceGuid"
          class="ma-1"
        />
      </v-toolbar>
      <div class="d-flex pa-5" v-if="loading">
        <h3 class="ma-auto">{{ $t("Loading") }}</h3>
      </div>
      <v-timeline align-top v-else-if="feedbacks.length !== 0" dense>
        <v-timeline-item
          :color="feedback.feedbackColor"
          small
          v-for="(feedback, index) in feedbacks"
          :key="index"
        >
          <v-row class="pt-1">
            <v-col>
              <v-card flat width="100%">
                <v-card-subtitle class="d-flex flex-wrap">
                  <p class="ma-1">{{ feedback.createdDate | moment("YYYY/MM/DD - h:mm a ") }}</p>
                  <p class="ma-1" v-if="isInRole(0)">{{ feedback.teacher.teacherName }}</p>
                  <v-spacer />
                  <div class="ma-1" v-if="isInRole(2)">
                    <edit-feedback
                      @editData="result => editFeedback(result)"
                      :currentFeedbackText="feedback.feedbackText"
                      :feedbackGuid="feedback.feedbackGuid"
                      class="ma-1"
                    />
                  </div>
                  <div class="ma-1">
                    <show-feedback :text="feedback.feedbackText" />
                  </div>
                  <div class="ma-1" v-if="isInRole(2)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip, attrs }">
                        <v-btn
                          fab
                          color="error"
                          @click="deleteClick(feedback)"
                          small
                          outlined
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                        >
                          <v-icon>
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t("portfolio.Feedback.delete") }}
                      </span>
                    </v-tooltip>
                  </div>
                </v-card-subtitle>
                <v-card-text id="feedback">
                  {{ feedback.feedbackText }}
                </v-card-text>
                <div class="ma-1">
                  <comments-dialog
                    :sourceGuid="feedback.feedbackGuid"
                    :sourceType="4"
                    :title="$t(`portfolio.Feedback.title`)"
                    :isButton="true"
                    :isOutlineButton="true"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <div class="d-flex pa-5" v-else>
        <h3 class="ma-auto">{{ $t("noData") }}</h3>
      </div>
    </v-card>
    <v-dialog
      v-if="isInRole(2)"
      persistent
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <!-- <template v-slot:default="dialog"> -->
      <v-card :loading="dialogLoading">
        <v-toolbar color="error" dark>{{ $t("portfolio.Feedback.delete") }}</v-toolbar>
        <v-card-text>
          <div class="pa-5 font-weight-bold">{{ $t("portfolio.Feedback.deleteConform") }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :loading="dialogLoading" color="error" @click="confirmDelete()">{{
            $t("ok")
          }}</v-btn>
          <v-btn :disabled="dialogLoading" text @click="dialog = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
      <!-- </template> -->
    </v-dialog>
  </v-container>
</template>

<script>
import addFeedback from "@/components/portfolio/feedback/addFeedback.vue";
import EditFeedback from "@/components/portfolio/feedback/editFeedback.vue";
import ShowFeedback from "@/components/portfolio/feedback/showFeedback.vue";
import CommentsDialog from "@/components/portfolio/comments/commentsDialog.vue";

export default {
  components: {
    addFeedback,
    EditFeedback,
    ShowFeedback,
    CommentsDialog
  },
  name: "worksampleFeedbacks",
  async created() {
    this.sourceGuid = this.$route.params.id;
    this.sourceType = 0;
    await this.get(this.sourceGuid, this.sourceType);
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogLoading: false,
      sourceGuid: null,
      sourceType: 0,
      feedbacks: [],
      feedback: {
        feedbackGuid: null,
        feedbackText: null,
        showComment: false,
        sourceGuid: null,
        sourceType: null,
        teacher: {
          teacherGuid: null,
          teacherName: null
        },
        createdDate: null,
        modifiedDate: null
      }
    };
  },
  methods: {
    async get(sourceGuid, sourceType) {
      this.feedbacks = [];
      this.feedback = {
        showComment: false,
        feedbackGuid: null,
        feedbackText: null,
        sourceGuid: null,
        sourceType: null,
        createdDate: null,
        modifiedDate: null,
        teacher: {
          teacherGuid: null,
          teacherName: null
        }
      };
      this.loading = true;
      await this.axios
        .get(`Feedback/Get?sourceGuid=${sourceGuid}&sourceType=${sourceType}`)
        .then(response => {
          const { data } = response.data;
          // console.log(response);
          if (Array.isArray(data)) {
            data.forEach(element => {
              element.showComment = false;
              this.feedbacks.push(element);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editFeedback(result) {
      const item = this.feedbacks.find(x => x.feedbackGuid === result.id);
      console.log(item, result);
      if (item) {
        item.feedbackText = result.feedbackText;
      }
    },
    getComment(feedback) {
      const item = this.feedbacks.find(x => x.feedbackGuid === feedback.feedbackGuid);
      // console.log(item);
      if (item) {
        if (item.showComment) {
          item.showComment = false;
        } else {
          item.showComment = true;
          // item.showComment = !item.showComment;
        }
      }
    },
    deleteClick(paramFeedback) {
      this.feedback = {
        feedbackGuid: null,
        feedbackText: null,
        showComment: false,
        sourceGuid: null,
        sourceType: null,
        teacher: {
          teacherGuid: null,
          teacherName: null
        },
        createdDate: null,
        modifiedDate: null
      };
      this.feedback.feedbackGuid = paramFeedback.feedbackGuid;
      this.feedback.feedbackText = paramFeedback.feedbackText;
      this.feedback.showComment = paramFeedback.showComment;
      this.feedback.sourceGuid = paramFeedback.sourceGuid;
      this.feedback.sourceType = paramFeedback.sourceType;
      this.feedback.createdDate = paramFeedback.createdDate;
      this.feedback.teacher.teacherGuid = paramFeedback.teacher.teacherGuid;
      this.feedback.teacher.teacherName = paramFeedback.teacher.teacherName;
      this.feedback.modifiedDate = paramFeedback.modifiedDate;
      this.dialog = true;
    },
    async confirmDelete() {
      this.dialogLoading = true;
      const index = this.feedbacks.findIndex(x => x.feedbackGuid === this.feedback.feedbackGuid);
      if (index !== undefined) {
        await this.axios
          .post("Feedback/Delete", { feedbackGuid: this.feedback.feedbackGuid })
          .then(() => {
            this.feedbacks.splice(index, 1);
            this.dialog = false;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .finally(() => (this.dialogLoading = false));
      }
      // setTimeout(() => {
      //   this.dialogLoading = false;
      //   this.feedbacks.splice(index, 1);
      //   if (this.dialog) this.dialog = false;
      // }, 1000);
    }
  }
};
</script>

<style lang="css" scoped>
#feedback {
  width: 100%;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  height: 80px;
  line-height: 1.4;
  display: -webkit-box;
}
</style>
