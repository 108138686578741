import { render, staticRenderFns } from "./reflections.vue?vue&type=template&id=b81681c0&scoped=true&"
import script from "./reflections.vue?vue&type=script&lang=js&"
export * from "./reflections.vue?vue&type=script&lang=js&"
import style0 from "./reflections.vue?vue&type=style&index=0&id=b81681c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b81681c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VAlert,VBottomNavigation,VCard,VCardActions,VCardText,VCardTitle,VPagination,VSlideXReverseTransition,VSpacer,VTimeline,VTimelineItem})
