<template>
  <v-container>
    <v-card flat>
      <v-toolbar color="primary white--text" flat>
        <v-card-title v-if="isInRole(2)">
          {{ $t("portfolio.TagsItem.title") }}
        </v-card-title>
      <v-card-title  v-if="isInRole(0)">
        <v-menu
          bottom
          absolute
          left
          origin="center"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  dark
                  text
                  class="ma-3"
                  @click="searchTag = null"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Tags
                </v-btn>
              </template>
              <span>{{ $t("portfolio.TagsItem.addNew") }}</span>
            </v-tooltip>
          </template>

          <v-list width="180px" max-width="100%">
            <!-- <v-tooltip>
                      <template v-slot:activator="{ on: tooltip }"> -->
            <!-- Fixed item -->
            <v-list-item class="fixed-item">
              <v-text-field
                @input="searchTag(searchTag)"
                :label="$t('portfolio.TagsItem.search')"
                clearable
                full-width
                v-model="searchTag"
                outlined
              >
              </v-text-field>
            </v-list-item>

            <!-- Scrollable items -->
            <div class="scrollable-list overflow-x-hidden">
              <v-list-item
                bottom
                v-for="(tag, i) in tagsFilter"
                :key="i"
                :title="tag.tagName"
                @mouseenter="tag.hover = true"
                @mouseover="tag.hover = true"
                @mouseleave="tag.hover = false"
                class="mb-2 mx-auto"
                :style="
                  `;background-color: ${tag.tagColor}; border-radius:5px;
                      width:90% !important;text-align:center;${i == 0 ? 'margin-top:20px;' : ''}`
                "
                :color="tag.tagColor"
              >
                <v-list-item-title
                  @click="setTagToWorkSample(tag)"
                  class="text-truncate"
                  :style="`color:${tag.tagTextColor} !important ;max-width:150px;cursor:pointer`"
                  >{{ tag.tagName }}</v-list-item-title
                >
                <v-list-item-icon>
                  <edit-tag-item
                    :tag="tag"
                    :hover="tag.hover"
                    @putTagMethod="t => editTagItem(t)"
                    @setTagForDelete="n => setTag(n)"
                  ></edit-tag-item>
                </v-list-item-icon>
              </v-list-item>
              <add-tag-item @postTagMethod="getTagsItem()"></add-tag-item>
            </div>
          </v-list>
        </v-menu>
      </v-card-title>
      </v-toolbar>
      <div class="d-flex pa-5" v-if="loading">
        <h3 class="ma-auto">{{ $t("Loading") }}</h3>
      </div>

      <v-timeline align-top v-else-if="tags.length !== 0" dense>
        <v-timeline-item :color="tag.tagColor" small v-for="(tag, index) in tags" :key="index">
          <v-row class="pt-1">
            <v-col cols="3">
              <!-- <v-btn color="warning" class="mx-2">
                <v-icon>mdi-pen</v-icon>
              </v-btn> -->
              <v-dialog
              v-if="isInRole(0)"
                v-model="tag.dialog"
                transition="dialog-bottom-transition"
                max-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" fab small color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <!-- <template v-slot:default="dialog"> -->
                  <v-card>
                    <v-toolbar color="error white--text">{{
                      $t("portfolio.TagsItem.delete")
                    }}</v-toolbar>
                    <v-card-text>
                      <h2 class="pa-10">{{ $t("portfolio.TagsItem.confirmDelete") }}</h2>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn :disabled="loadingDelete" text @click="tag.dialog = false">{{
                        $t("close")
                      }}</v-btn>
                      <v-btn
                        color="error white--text"
                        :loading="loadingDelete"
                        @click="removeTagToWorkSample(tag.workSampleGuid, tag)"
                        >{{ $t("ok") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                <!-- </template> -->
              </v-dialog>
            </v-col>
            <v-col>
              <v-chip :color="tag.tagColor" label>
                <v-icon :style="`color:${tag.tagTextColor};font-size:medium;`">
                  {{ tag.tagName }}
                </v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <div class="d-flex pa-5" v-else>
        <h3 class="ma-auto">{{ $t("noData") }}</h3>
      </div>
    </v-card>
    <delete-tag-item
      @closeTagDelete="dialog = false"
      @refreshTags="get(id)"
      :tag="tag"
      :dialog="dialog"
    >
    </delete-tag-item>
  </v-container>
</template>

<script>
import addTagItem from "../../../components/portfolio/TagsItem/addTagItem.vue";
import DeleteTagItem from "../../../components/portfolio/TagsItem/deleteTagItem.vue";
import EditTagItem from "../../../components/portfolio/TagsItem/editTagItem.vue";

export default {
  components: { addTagItem, EditTagItem, DeleteTagItem },
  data() {
    return {
      id: null,
      swatches: [
        {
          hexa: "#02bdad",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#feae4c",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#df67ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#626262",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#f6b800",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ff5a43",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5cc0f5",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#dfdfdf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#03cea4",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#512df6",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#3752af",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#5d03bd",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#0089ff",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#ff278f",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#a75f0c",
          selected: false,
          textColor: "#ffffff"
        },
        {
          hexa: "#d7e4ef",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#fededf",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#efe5ce",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#a2a2a2",
          selected: false,
          textColor: "#000000"
        },
        {
          hexa: "#ffe01a",
          selected: false,
          textColor: "#000000"
        }
      ],
      tags: [],
      tagsItem: [],
      loading: false,
      loadingDelete: false,
      dialog: false,
      deleteDialog: false,
      searchTag: null,
      tag: {
        tagColor: null,
        tagItemGuid: null,
        tagName: null,
        tagTextColor: null,
        dialog: false,
        show: false,
        tagsLoading: false
      }
    };
  },
  methods: {
    getTagsItem() {
      this.tagsLoading = true;
      this.tagsItem = [];
      this.axios
        .get("TagItem/Get")
        .then(response => {
          const { data } = response.data;
          // console.log(response.data);
          if (Array.isArray(data)) {
            data.forEach(sample => {
              // eslint-disable-next-line array-callback-return
              const color = this.swatches.find(x => {
                if (
                  sample.tagColor !== undefined &&
                  sample.tagColor !== "undefined" &&
                  x.hexa === sample.tagColor
                ) {
                  return x;
                }
              });
              // console.log(color);
              const tag = {
                tagColor: sample.tagColor,
                tagItemGuid: sample.tagItemGuid,
                tagName: sample.tagName,
                tagTextColor: color !== undefined ? color.textColor : "white",
                hover: false
              };
              this.tagsItem.push(tag);
            });
            if (this.tagsItem.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(err => {
          console.log("error:", err);
        })
        .finally(() => {
          this.tagsLoading = false;
        });
    },
    async get(id) {
      this.loading = true;
      this.tags = [];
      await this.axios
        .get(`Tag/Get?workSampleGuid=${id}`)
        .then(response => {
          const { data } = response.data;
          if (Array.isArray(data)) {
            data.forEach(sample => {
              // eslint-disable-next-line array-callback-return
              const color = this.swatches.find(x => {
                if (
                  sample.tagItem.tagColor !== undefined &&
                  sample.tagItem.tagColor !== "undefined" &&
                  x.hexa === sample.tagItem.tagColor
                ) {
                  return x;
                }
              });
              // console.log(sample);
              const tag = {
                tagColor: sample.tagItem.tagColor,
                tagItemGuid: sample.tagItem.tagItemGuid,
                tagGuid: sample.tagGuid,
                tagName: sample.tagItem.tagName,
                tagTextColor: color !== undefined ? color.textColor : "white",
                dialog: false
              };
              this.tags.push(tag);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editTagItem(tag) {
      // console.log("Edited");
      // eslint-disable-next-line prefer-const
      let object = this.tagsItem.find(x => x.tagItemGuid === tag.tagItemGuid);
      if (object) {
        object.tagName = tag.tagName;
        object.tagColor = tag.tagColor;
        object.tagTextColor = tag.textColor;
      }
      const tagInTags = this.tags.find(x => x.tagItemGuid === tag.tagItemGuid);
      if (tagInTags) {
        tagInTags.tagName = tag.tagName;
        tagInTags.tagColor = tag.tagColor;
        tagInTags.tagTextColor = tag.textColor;
      }
    },
    setTag(item) {
      this.tag.tagColor = item.tagColor;
      this.tag.tagItemGuid = item.tagItemGuid;
      this.tag.tagTextColor = item.tagTextColor;
      this.tag.tagName = item.tagName;
      this.dialog = true;
    },
    async setTagToWorkSample(tag) {
      if (Array.isArray(this.tags)) {
        const exists = this.tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
        if (!exists) {
          this.loading = true;

          // Add the tag to the array if it's not already present
          // const switchItem = this.swatches.find(sw => sw.hexa === tag.tagColor);
          // const toAddTag = {
          //   tagItemGuid: tag.tagItemGuid,
          //   tagName: tag.tagName,
          //   tagColor: tag.tagColor,
          //   hover: false,
          //   tagTextColor: switchItem !== undefined ? switchItem.textColor : "white"
          // };
          await this.axios
            .post("Tag/Create", {
              tagItemGuid: tag.tagItemGuid,
              workSampleGuid: this.$route.params.id
            })
            .then(result => {
              // console.log(result);
              if (result.data.details === "Tag Already Exist.") {
                this.loading = false;
                this.$swal.fire({
                  title: this.$t("portfolio.TagsItems.AlreadyExist"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: toast => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  }
                });
                return;
              }
              if (Array.isArray(this.tags)) {
                this.get(this.id);
              }
              this.loading = false;
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            })
            .catch(() => {
              this.loading = false;
              this.$swal.fire({
                title: this.$t("AnErrorOccurredDuringTheProcess"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            });
          // setTimeout(() => {
          //   tags.push(toAddTag);
          //   this.loading = false;
          // }, 300);
        }
      }
    },
    async removeTagToWorkSample(sampleId, tag) {
      if (Array.isArray(this.tags)) {
        const exists = this.tags.some(obj => tag.tagItemGuid === obj.tagItemGuid);
        console.log(exists, tag);

        if (exists) {
          // this.$emit("removeTagToWorkSample", {
          //   isloadingOn: true
          // });
          // Add the tag to the array if it's not already present
          const tagItem = this.tags.findIndex(sw => sw.tagItemGuid === tag.tagItemGuid);
          const tagItemObj = this.tags.find(sw => sw.tagItemGuid === tag.tagItemGuid);
          // console.log(tagItemObj);
          this.loadingDelete = true;
          await this.axios
            .post("Tag/Delete", { tagGuid: tagItemObj.tagGuid })
            .then(() => {
              this.tags.splice(tagItem, 1);
              // this.$emit("removeTagToWorkSample", {
              //   isloadingOn: false
              // });
              // this.removeTagToWorkSampleMenu(sampleId, tag);
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
              this.tags = [];
              this.get(this.$route.params.id);
            })
            .catch((e) => {
              // this.$emit("removeTagToWorkSample", {
              //   isloadingOn: false
              // });
              console.log(e);
              this.$swal.fire({
                title: this.$t("AnErrorOccurredDuringTheProcess"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            })
            .finally(() => {
              this.loadingDelete = false;
            });
          // setTimeout(() => {
          //   tags.splice(tagItem, 1);
          //   this.loading = false;
          // }, 300);
        }
      }
    }
  },
  async created() {
    this.id = this.$route.params.id;
    this.get(this.id);
    this.getTagsItem();
  },
  computed: {
    tagsFilter() {
      const data = this.tagsItem;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.searchTag) {
        return data.filter(
          item =>
            // eslint-disable-next-line implicit-arrow-linebreak
            this.searchTag
              .toLowerCase()
              .split(" ")
              .every(v => item.tagName.toLowerCase().includes(v))
          // eslint-disable-next-line function-paren-newline
        );
      } else {
        return data;
      }
    }
  }
};
</script>

<style scoped>
#tagSearch {
  width: 150px !important;
  max-width: 100% !important;
}
#tagSearch >>> * {
  font-size: 14px !important;
}

.fixed-item {
  display: flex;
  height: 10px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30px;
  /* Optional, add background color as needed */
  z-index: 1; /* Optional, adjust z-index as needed */
  /* Add any other styles for your fixed item */
}

.scrollable-list {
  z-index: 2;
  margin-top: 70px;
  max-height: 250px; /* Set the maximum height for scrollable list */
  overflow-y: auto; /* Add a vertical scrollbar if necessary */
  /* Add any other styles for your scrollable list */
}
</style>
