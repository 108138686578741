var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"containerCard overflow-y-auto",attrs:{"flat":"","max-height":"600"}},[_c('v-toolbar',{attrs:{"color":"primary white--text","flat":""}},[_c('v-card-title',{staticClass:"ma-1"},[_vm._v("\n        "+_vm._s(_vm.$t("portfolio.comment.title"))+"\n      ")]),_c('v-spacer')],1),(_vm.loading)?_c('div',{staticClass:"d-flex pa-5"},[_c('h3',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.$t("Loading")))])]):(_vm.comments.length !== 0)?_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"form-create-comment"},[_c('v-card',{attrs:{"flat":"","width":"100%"}},[_c('v-form',{ref:"form",staticClass:"pa-5",staticStyle:{"width":"100%","border-radius":"5px"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{staticClass:"comments",attrs:{"outlined":"","full-width":"","loading":_vm.loadingText,"auto-grow":"","rules":_vm.commentRules,"clearable":"","name":"input-7-4","label":_vm.$t('portfolio.comment.add')},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}},[_c('v-btn',{attrs:{"slot":"prepend","icon":""},on:{"click":function($event){return _vm.resetValidation()}},slot:"prepend"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-restart")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("portfolio.comment.restart")))])])],1),_c('v-btn',{attrs:{"slot":"prepend","icon":""},on:{"click":function($event){return _vm.addComment()}},slot:"prepend"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"open-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!hover ? '' : 'primary'}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-comment-plus-outline")])]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("portfolio.comment.add")))])])],1)],1)],1)],1)],1),_vm._l((_vm.comments),function(comment,index){return _c('v-list-item',{key:index,attrs:{"color":comment.commentColor,"small":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('v-list-item-avatar',{staticClass:"white--text",attrs:{"color":"primary","size":"50"}},[_c('p',{staticClass:"pa-0 ma-0"},[_vm._v("\n                "+_vm._s(_vm.avatarText(comment))+"\n              ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"pb-1 ma-0"},[_vm._v("\n                "+_vm._s(_vm.getUserInputComment(comment))+"\n              ")]),_c('abbr',{staticStyle:{"font-size":"12px"}},[_vm._v("\n                "+_vm._s(_vm._f("moment")(comment.createdDate,"YYYY/MM/DD - h:mm a"))+"\n              ")])])],1),_c('v-list-item-subtitle',[_c('v-textarea',{staticClass:"comments py-3",attrs:{"outlined":comment.focus,"auto-grow":"","loading":comment.loading,"filled":!comment.focus,"readonly":!comment.focus || comment.loading},model:{value:(comment.commentText),callback:function ($$v) {_vm.$set(comment, "commentText", $$v)},expression:"comment.commentText"}},[(_vm.showActions(comment))?_c('v-btn',{attrs:{"slot":"append","icon":""},on:{"click":function($event){return _vm.showDeleteCommentDialog(comment)}},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-delete-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(comment.modifiedDate),expression:"comment.modifiedDate"}],attrs:{"slot":"append","icon":""},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-receipt-text-edit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(comment.modifiedDate,"YYYY/MM/DD - h:mm a")))])])],1),(!comment.focus && _vm.showActions(comment))?_c('v-btn',{attrs:{"slot":"append","icon":""},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"open-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!hover ? '' : 'warning'},on:{"click":function($event){comment.focus = true}}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-pen")])]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])])],1):_vm._e(),(comment.focus && _vm.showActions(comment))?_c('v-btn',{attrs:{"slot":"append","icon":""},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"open-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!hover ? '' : 'warning'},on:{"click":function($event){return _vm.saveComment(comment)}}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-content-save-outline")])]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("save")))])])],1):_vm._e()],1)],1)],1)],1)})],2):_c('div',{staticClass:"d-flex pa-5"},[_c('h3',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.$t("noData")))]),_c('v-form',{ref:"form",staticClass:"pa-5 form-create-comment",attrs:{"s":"","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{staticClass:"comments",attrs:{"outlined":"","loading":_vm.loadingText,"auto-grow":"","rules":_vm.commentRules,"clearable":"","name":"input-7-4","label":_vm.$t('portfolio.comment.add')},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}},[_c('v-btn',{attrs:{"slot":"prepend","icon":""},on:{"click":function($event){return _vm.resetValidation()}},slot:"prepend"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-restart")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("portfolio.comment.restart")))])])],1),_c('v-btn',{attrs:{"slot":"append","icon":""},on:{"click":function($event){return _vm.addComment()}},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{attrs:{"open-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":!hover ? '' : 'primary'}},'v-icon',attrs,false),on),[_vm._v("\n                    mdi-comment-plus-outline")])]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("portfolio.comment.add")))])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"error","dark":""}},[_vm._v(_vm._s(_vm.$t("portfolio.comment.delete")))]),_c('v-card-text',[_c('h4',{staticClass:"pa-5"},[_vm._v("\n            "+_vm._s(_vm.$t("portfolio.comment.deleteConform"))+"\n          ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteComment(_vm.currentComment)}}},[_vm._v(_vm._s(_vm.$t("ok"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t("close"))+" ")])],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }