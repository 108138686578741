<template>
  <div>
    <template>
      <v-card class="overflow-y-auto" min-height="400px">
        <v-bottom-navigation absolute>
          <v-pagination
            class="py-1"
            v-model="page"
            :length="Math.ceil(reflections.length / perPage)"
            total-visible="15"
          ></v-pagination>
        </v-bottom-navigation>
        <v-card-title class="primary white--text">
          <span class="">{{ $t("portfolio.Reflections.title") }}</span>
          <v-spacer></v-spacer>
          <!-- <v-btn
            :outlined="interval == null"
            :color="interval == null ? 'white' : 'primary'"
            dark
            depressed
            @click="interval == null ? start() : stop()"
          >
            Realtime Logging
          </v-btn> -->
          <add-reflection v-if="isInRole(0)" @addReflection="get(id)"></add-reflection>
        </v-card-title>
        <v-card-text class="py-0">
          <div class="d-flex" v-if="loading">
            <h2 class="mx-auto pa-4">{{ $t("Loading") }}</h2>
          </div>
          <v-timeline align-top class="mb-5" dense v-else-if="reflections.length != 0">
            <v-slide-x-reverse-transition group hide-on-leave>
              <v-timeline-item
                v-for="(item, i) in reflections"
                :key="i"
                color="primary"
                small
                fill-dot
              >
                <v-alert :value="true" icon="mdi-light" class="white--text reflection-content">
                  <v-card class="py-3 px-4 position-relative" outlined>
                    <v-card class=" mb-1 py-3 overflow-y-hidden" flat max-height="250px">
                      <article class="html-text" v-html="item.reflectionText"></article>
                    </v-card>
                    <v-card-actions>
                      <!-- <v-btn color="warning">{{ $t("edit") }}</v-btn> -->
                      <edit-reflection
                        v-if="isInRole(0)"
                        :id="item.reflectionGuid"
                        :reflectionText="item.reflectionText"
                        @editReflection="
                          n => {
                            item.reflectionText = n.reflectionText;
                          }
                        "
                      ></edit-reflection>
                      <show-reflection
                        :id="item.reflectionGuid"
                        :reflectionText="item.reflectionText"
                      ></show-reflection>
                      <delete-reflection
                        v-if="isInRole(0)"
                        @deleteDone="removeReflection(item.reflectionGuid)"
                        :id="item.reflectionGuid"
                      ></delete-reflection>
                      <div class="ma-1">
                        <feedback-dialog
                          :isButton="true"
                          :sourceGuid="item.reflectionGuid"
                          :sourceType="1"
                        />
                      </div>
                      <div class="ma-1">
                        <comments-dialog
                          :sourceGuid="item.reflectionGuid"
                          :sourceType="1"
                          :title="$t(`portfolio.Reflections.title`)"
                          :isButton="true"
                        />
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-alert>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
          </v-timeline>
          <div class="d-flex" v-else>
            <h2 class="mx-auto pa-4">{{ $t("noData") }}</h2>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import addReflection from "@/components/portfolio/Reflections/addReflection.vue";
import DeleteReflection from "@/components/portfolio/Reflections/deleteReflection.vue";
import EditReflection from "@/components/portfolio/Reflections/editReflection.vue";
import ShowReflection from "@/components/portfolio/Reflections/showReflection.vue";
import FeedbackDialog from "@/components/portfolio/feedback/feedbackDialog.vue";
import CommentsDialog from "@/components/portfolio/comments/commentsDialog.vue";

export default {
  components: {
    addReflection,
    EditReflection,
    ShowReflection,
    DeleteReflection,
    FeedbackDialog,
    CommentsDialog
  },
  data: () => ({
    id: null,
    loading: false,
    reflections: [],
    page: 1,
    perPage: 8
  }),
  methods: {
    removeReflection(id) {
      // Find the index of the object with the specified id
      const indexToRemove = this.reflections.findIndex(item => item.reflectionGuid === id);

      // Check if the index is found (not -1) and then remove the object
      if (indexToRemove !== -1) {
        this.reflections.splice(indexToRemove, 1);
      }
    },
    async get(id) {
      this.reflections = [];
      this.loading = true;
      await this.axios
        .get(`Reflection/Get?workSampleGuid=${id}`)
        .then(response => {
          const { data } = response.data;
          if (Array.isArray(data)) {
            data.forEach(reflection => {
              reflection.comments = [];
              this.reflections.push(reflection);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.get(this.id);
  }
};
</script>

<style scoped>
.html-text {
  width: 100%;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
}
</style>
